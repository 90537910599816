import { createTextVNode as _createTextVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NInput"], {
    value: $setup.inputValue,
    placeholder: $props.placeholder,
    disabled: $props.disabled,
    onInput: $setup.onInput,
    onBlur: $setup.onBlur,
    onKeydown: _withKeys($setup.onBlur, ["enter"])
  }, {
    suffix: _withCtx(() => [
      _createTextVNode("%")
    ]),
    _: 1
  }, 8, ["value", "placeholder", "disabled", "onKeydown"]))
}