import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "edit-shipping-description-modal-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "edit-shipping-description-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Edit description"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["NInput"], {
        class: "description-textarea",
        value: $setup.newDescription,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.newDescription) = $event)),
        type: "textarea",
        placeholder: "Enter text..."
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.newDescription), 1)
        ]),
        _: 1
      }, 8, ["value"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["NButton"], {
          class: "update-button medium-button n-button-save",
          type: "info",
          round: "",
          onClick: $setup.onUpdateDescription
        }, {
          default: _withCtx(() => [
            _createTextVNode("Update")
          ]),
          _: 1
        }),
        _createVNode($setup["NButton"], {
          class: "medium-button n-button-cancel",
          type: "tertiary",
          round: "",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}